import React from "react";
import { graphql } from "gatsby";
// import pathBuilder from "../utils/pathBuilder"
// import Layout from "../../components/Layout/Layout"
// import styled from "styled-components"
// import theme from "../theme/variables"
import IndexTemplate from "../../templates/home";

const environment = {
  lang: "en",
  path: "/en",
  altHrefLang: [
    {
      lang: "nl",
      path: "/",
    },
    {
      lang: "en",
      path: "/en",
    },
  ],
  theme: "green",
};

const IndexEn = (props) => {
  return <IndexTemplate {...props} environment={environment} />;
};

export default IndexEn;

export const IndexQueryEn = graphql`
  {
    imgDesktop: file(relativePath: { eq: "index-banner-desktop-en.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imgMobile: file(relativePath: { eq: "index-banner-mobile-en.png" }) {
      childImageSharp {
        gatsbyImageData(width: 820, layout: CONSTRAINED)
      }
    }
  }
`;
